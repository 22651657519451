@import '../../assets/scss/variables.scss';

.workspace {
  position: relative;
  height: fit-content;
}

.card {
  margin: 10px;
  padding: 20px;
  width: 345px;
  height: auto;
  min-height: 104px;
  background: $grey0-color;
  border: 1px solid #cecece;
  border-radius: 19px;
  font-family: $font-family;
  text-align: left;
}

.card:hover + .iconButton,
.iconButton:hover {
  opacity: 1;
}

.title {
  font-size: $font-size-lg;
  color: $grey4-color;
  margin: 0;
}

.date {
  font-size: $font-size-xsmall;
  color: $grey2-color;
  margin-top: 15px;
}

.description {
  margin: 0;
  width: 80%;
  font-size: 14px;
  color: #464646;
}

.button {
  font-family: $font-family;
  margin-top: 15px;
  font-size: $font-size-xsmall;
  color: #464646;
}

.icon {
  padding-right: 15px;
  cursor: pointer;
}

.iconButton {
  text-align: right;
  opacity: 0;
  position: absolute;
  bottom: 20px;
  right: 10px;
}
