@import '../../assets/scss/variables.scss';

.deletedItens {
  display: flex;
  flex-flow: row wrap;
}

.item {
  cursor: pointer;
  display: flex;
  background-color: $grey0-color;
  color: $grey4-color;
  border-radius: 0.7rem;
  border: 0 solid transparent;
  vertical-align: middle;
  margin: 2px;
  position: relative;
  p {
    top: 4px;
    position: relative;
    left: 6px;
    font-size: $font-size-xxsmall;
  }

  button {
    height: 2px;
  }
}

.item:hover {
  background-color: $grey1-color;
  color: $grey4-color;
}
