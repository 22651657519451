@import '../../assets/scss/variables.scss';

.header {
  width: 100%;
  justify-content: space-between;
  background-color: $grey0-color;
  display: flex;
  padding: 10px;
  height: 50.78px;
}

.logo {
  padding-top: 7px;
}

.label {
  cursor: pointer;
}
