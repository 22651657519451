@import 'assets/scss/variables.scss';

*,
*::before,
*::after {
  box-sizing: inherit;
  font-family: $font-family;
}

html {
  box-sizing: border-box;
  overflow-x: hidden;
  color: $grey5-color;
}

body {
  margin: 0;
  overflow-x: hidden;
}
// Resetando estilos padrão
img {
  max-width: 100%;
}

button {
  background: transparent;
  border: none;
  font-size: inherit;
  cursor: pointer;
}

ul,
ol {
  padding: 0 0 0 1em;
}

// lis should have vertical margins and inherit their parents'
// line-heights for better readability
li {
  margin: 1.5em 0;
  line-height: inherit;
}
