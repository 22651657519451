@import '../../assets/scss/variables.scss';

.button {
  background: $background-color;
  border: 2px solid $grey1-color;
  box-sizing: border-box;
  border-radius: 12px;
  height: 46px;
  padding: 0 3em;
  font-family: $font-family;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  width: 100%;
  margin-top: 15px;
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;
}
