@import '../../assets/scss/variables.scss';

.page {
  padding: 30px;
  text-align: center;
}

.row {
  display: flex;
  margin-top: 20px;
}

.mainForm {
  display: inline-block;
  margin-top: 5%;
}

.title {
  font-weight: bold;
}

.logo {
  width: 150px;
  margin-bottom: 25px;
}
