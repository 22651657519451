@import '../../assets/scss/variables.scss';

.rulePage {
  display: flex;
  justify-content: center;
  padding: 70px;
  border-bottom: 2px solid $grey1-color;
}

.simulation {
  button {
    width: 180px;
  }
}

.contentLeft {
  width: 500px;
}

.contentRight {
  width: 700px;
}

.errorMessage {
  color: red;
  margin-left: 20px;
}
