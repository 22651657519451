@import '../../assets/scss/variables.scss';

.simulationForm {
  display: flex;
  flex-direction: row;
}

.textColumn {
  min-width: 400px;
  padding: 20px 20px 20px 0px;

  p {
    font-size: $font-size-xsmall;
    color: $grey4-color;
    text-align: left;
    white-space: nowrap;
  }
}

.smallText {
  font-size: $font-size-xxsmall;
}

.fieldContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 22px;
  margin-bottom: 14px;

  .field {
    width: 150px;
    text-align: left;
  }
}
