@import '../../assets/scss/variables.scss';

.search {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid $grey1-color;
  width: 250px;
}

.input {
  margin-left: 10px;
  margin-bottom: 2px;
  width: 100%;
  border: 0;
  font-family: $font-family;
  font-size: $font-size-xsmall;
  line-height: 14px;
}
