@import '../../assets/scss/variables.scss';

.home {
  padding: 40px;
}

.title {
  font-size: $font-size-xlg;
  margin: 0;
  color: $grey4-color;
}

.subTitle {
  font-size: $font-size-xsmall;
  color: $grey4-color;
}

.row {
    width: 530px;
    column-count: 2;
    button {
        margin: 0;
    }
}

.search {
    padding-top: 10px;
}

.workspaces {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 70px;
}