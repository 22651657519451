@import '../../assets/scss/variables.scss';

.page {
  padding: 30px;
  display: flex;

  .smallText {
    font-size: $font-size-xsmall;
  }
  .description {
    font-size: $font-size-xsmall;
    padding-top: 10px;
  }
}

.leftSide {
  width: 318px;
}

.rightSide {
  flex: 1;
}

.title {
  font-size: $font-size-xlg;
  margin: 0;
  color: $grey4-color;
}
