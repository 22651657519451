@import '../../assets/scss/variables.scss';

.input {
  color: $grey4-color;
  font-family: $font-family;
  font-size: $font-size-xlg;
  border: 0;
}

.row {
  display: inline;
}

.row:hover .iconDisplay {
  opacity: 1;
}

.iconDisplay {
  opacity: 0;
}
