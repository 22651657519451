$white-color: #ffffff;
$line-color: #ACACAC;
$background-color: #FBFBFB;
$header-color: #F7F7F7;

$grey0-color: #EEEEEE;
$grey1-color: #CECECE;
$grey2-color: #C4C4C4;
$grey3-color: #858585;
$grey4-color: #464646;
$grey5-color: #919aad;
$blue-color: #65708b;
$black-color: #111111;

$font-size-xxsmall: 10px;
$font-size-xsmall: 12px;
$font-size-small: 14px;
$font-size: 16px;
$font-size-med: 18px;
$font-size-lg: 20px;
$font-size-xlg: 24px;
$font-size-xxlg: 30px;
$font-size-xxxlg: 40px;
$font-size-xxxxlg: 60px;

$padding-global-left: 55px;
$padding-global-right: 45px;
$font-family: Roboto;