@import '../../assets/scss/variables.scss';


.input {
    color: $grey3-color;
    outline: 0;
    border-width: 0 0 2px;
    border-color: $grey1-color;
    font-size: $font-size-xsmall;
    width: 50px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


input[type=number] {
    -moz-appearance:textfield;
}