@import '../../assets/scss/variables.scss';

.headerGroups {
  display: flex;
  margin-top: 100px;
}

.header {
  display: flex;
  height: 24px;
}

.headerSector {
  display: flex;
  height: 24px;
  padding-top: 10px;
}

.item {
  width: 40px;
  margin: 0;
}

.disabled {
  color: $grey2-color;
}

.label {
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  padding-right: 10px;
}

.containerItems {
  display: flex;
  flex: 2;
}

.itemText {
  font-size: $font-size-xsmall;
  white-space: nowrap;
  transform: rotate(-90deg);
  transform-origin: left top;
  padding: 0;
  margin: 0;
}
