@import '../../assets/scss/variables.scss';

.div {
  margin: 0;
  position: relative;
}

.search {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid $grey1-color;
}

.input {
  color: $grey4-color;
  border: 0;
  font-size: $font-size-xsmall;
  padding-left: 12px;
  margin-bottom: 0px !important;
}

.list {
  padding: 0;
  margin-top: 0px;
  -webkit-transition: opacity 0.1s ease;
  transition: opacity 0.1s ease;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  max-height: 100px;
  overflow-y: scroll;
  position: relative;
  width: 160px;
  z-index: 100;
}

.item {
  font-size: $font-size-xxsmall;
  text-align: left;
  color: $black-color;
  background-color: $background-color;
  top: 100%;
  margin: 0;
  padding: 7px;
  border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  cursor: pointer;
  display: block;
  word-wrap: break-word;
  width: 150px;
}

.item:hover {
  background-color: $grey0-color;
}

.delete {
  position: absolute;
  right: -5px;
  top: -8px;
  outline: none;
}

.label {
  position: absolute;
  left: 100%;
  top: -10px;
}
