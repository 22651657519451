@import '../../assets/scss/variables.scss';

.modal {
  z-index: 100;
  padding-top: 100px;
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modalWrapper {
  background: $background-color;
  border: 2px solid $grey1-color;
  border-radius: 12px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  margin: 100px auto 0;
  transition: all 0.8s;
  font-family: $font-family;
  width: 60%;
}

.modalHeader {
  height: 40px;
  line-height: 40px;
  padding: 5px 20px;
  text-align: center;
}

.modalBody {
  padding: 10px 15px;
  text-align: center;
}

.modalFooter {
  height: 55px;
  padding: 15px;
  margin: 0% 20%;
}

.modalFooter button {
  background: $background-color;
  border: 2px solid $grey1-color;
  box-sizing: border-box;
  border-radius: 12px;
  height: 30px;
  padding: 0 3em;
  font-family: $font-family;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  cursor: pointer;
}

.btnCancel {
  float: left;
}

.btnContinue {
  float: right;
}
