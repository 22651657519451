.features {
  padding: 20px;
  text-align: left;
  column-count: 2;
  column-gap: 60px;
  justify-content: center;
  .featureItem {
      padding: 10px !important;
  }
}
