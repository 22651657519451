@import '../../assets/scss/variables.scss';

.input {
  color: $grey4-color;
  font-family: $font-family;
  font-size: $font-size-xlg;
  border: 0;
  padding: 10px;
}
.input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #464646;
  opacity: 0.5; /* Firefox */
}

.row {
  display: inline;
}
