@import '../../assets/scss/variables.scss';

.card {
  margin: 15px;
  width: 280px;
  height: 70px;

  background: $background-color;
  border: 2px solid $grey1-color;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 10px;
}

.ruleText {
  font-size: $font-size-xsmall;
  color: $grey4-color;
}

.icon {
  padding-right: 15px;
  cursor: pointer;
}

.row {
  display: flex;
}