@import '../../assets/scss/variables.scss';

.line {
  position: absolute;
  width: 100%;
  height: 0px;

  border: 0.3px solid #858585;
  transform: rotate(180deg);
}

.label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  text-align: right;

  color: #464646;
}

.ellipse {
  position: absolute;
  width: 14px;
  height: 14px;

  border-radius: 50%;
  background: #858585;
  border: 1px solid #858585;
  box-sizing: border-box;

  margin-top: -6px;
  margin-left: 0%;
}

.row {
  display: flex;
  margin-top: 20px;
}
.labelCol {
  padding-right: 20px;
  padding-left: 10px;
  width: 25%;
  text-align: right;
  overflow-wrap: break-word;
}
.lineCol {
  padding-top: 10px;
  width: 70%;
  position: relative;
}
.componentBody {
  position: relative;
}

.titleCol {
  padding-right: 20px;
  padding-left: 10px;
  width: 25%;
}
.paramsCol {
  padding-top: 10px;
  width: 70%;
  justify-content: space-between;
  display: flex;
}
