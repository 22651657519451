.treeWrapper {
  height: 400px;
  margin: 0 auto;
  text-align: center;
  width: 500px;
}

.deviationWrapper {
  margin: 50px auto;
  width: 700px;
  text-align: center;
}
