@import '../../assets/scss/variables.scss';

.item {
  padding: 3px;
}

.checkbox {
  label {
    font-family: $font-family !important;
    color: $grey4-color !important;
    font-size: $font-size-xsmall !important;
  }
}
