@import '../../assets/scss/variables.scss';

.tabTitle {
  font-family: $font-family !important;
  margin-bottom: 0 !important;
  font-size: $font-size-xsmall;
  font-weight: normal !important;
}

.container {
  display: flex;
  justify-content: center;
  background: #fff;
  box-shadow: 0 1px 1px 0 $grey1-color;
  padding: 10px;
  border-radius: 0.28571429rem;
  border-bottom: 1px solid $grey1-color;
}
