.container {
  display: flex;
  justify-content: center;

  .message {
    position: absolute;
    background-color: #ffe8e6;
    width: 200px;
    color: #db2828;
    box-shadow: 0 0 0 1px #db2828 inset, 0 0 0 0 transparent;
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 0px 10px;
    border-radius: 0.28571429rem;
    z-index: 3000;
    p {
      top: 2px;
      position: relative;
      font-size: 14px;
    }
  }
}
