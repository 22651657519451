@import '../../assets/scss/variables.scss';

:global .clusters {
  rect {
    fill: #00ffd0;
  }

  text {
    font-weight: 300;
    font-size: 14px;
  }
}

:global .node rect {
  stroke: #999;
  fill: #fff;
  stroke-width: 1.5px;
}

:global .edgePath path {
  stroke: #333;
  stroke-width: 1.5px;
}

.row {
  display: inline;
}

.divLabel:not(:last-child) {
  margin-bottom: 55px;
}

.divLabel div {
  margin-top: 1px;
}
