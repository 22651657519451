@import '../../assets/scss/variables.scss';

.input {
  color: $grey3-color;
  font-size: $font-size-xsmall;
  border: 0;
  width: 300px;
  resize: none;
}

.row {
  display: inline;
  border-bottom: 1px solid $grey1-color;
  border-bottom: 2px solid rgba(206, 206, 206, 0.3);
}

.row:hover .iconDisplay {
  opacity: 1;
}

.iconDisplay {
  opacity: 0;
}
